import '../../../styles/map-controls.scss';

import { Button } from 'antd';
import React from 'react';

import MinusIcon from '../../icons/minus';
import PlusIcon from '../../icons/plus';

export interface MapControlsProps {
  map?: mapboxgl.Map;
}

const MapControls: React.FC<MapControlsProps> = ({ map }) => {
  return (
    <div className="map-controls">
      <div>
        <Button
          onClick={() => map?.zoomIn()}
          size="large"
          type="link"
          icon={<PlusIcon />}
          aria-label="plus"
        ></Button>
        <Button
          onClick={() => map?.zoomOut()}
          size="large"
          type="link"
          icon={<MinusIcon />}
          aria-label="minus"
        ></Button>
      </div>
    </div>
  );
};

export default MapControls;
