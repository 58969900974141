import React, { memo, useEffect, useRef, useState } from 'react';

import { win } from '../fragments';
import { debounce } from '../utils';

const NON_WEBGL_DEFAULT_OPTIONS = {
  mouseForce: 60,
  particleSize: 3,
  noise: 15,
  renderer: 'default',
  particleGap: 2,
};

interface Props {
  imageSrc: string;
  maxHeight?: number;
  height?: number;
}

const Particles: React.FC<Props> = ({ height, imageSrc }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [nextParticleInstance, setNextParticleInstance] = useState<any>();
  const [width, setWidth] = useState<number>(win?.innerWidth ?? 0);

  const setWidthDebounced = useRef(
    debounce(500, () => setWidth(win?.innerWidth ?? 0)),
  ).current;

  const imageRef = useRef<HTMLImageElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  // Initialize NextParticle instance
  useEffect(() => {
    setTimeout(() => {
      if (process.env.NODE_ENV === 'production') {
        const imageElement = imageRef.current;
        const canvasElement = canvasRef.current;

        if (imageElement && canvasElement) {
          imageElement.src = imageSrc;

          const options = {
            ...NON_WEBGL_DEFAULT_OPTIONS,
            ...(height ? { height } : {}),
            canvas: canvasElement,
            image: imageElement,
            width: win?.innerWidth ?? 0,
          };

          setNextParticleInstance(new NextParticle(options));
        }
      }
    }, 0);
  }, [canvasRef, imageRef]);

  useEffect(() => {
    if (
      nextParticleInstance !== undefined &&
      nextParticleInstance.state !== 'stopped'
    ) {
      nextParticleInstance.stop();
      nextParticleInstance.width = width;

      setTimeout(() => {
        nextParticleInstance.start();
      }, 50);
    }
  }, [nextParticleInstance, width]);

  // Handle particle instance destroying
  useEffect(() => {
    if (nextParticleInstance) {
      return () => nextParticleInstance.stop(undefined);
    }
  }, [nextParticleInstance]);

  // Handle window resize
  useEffect(() => {
    if (canvasRef.current && nextParticleInstance) {
      win?.addEventListener('resize', setWidthDebounced, { passive: true });

      return () => win?.removeEventListener('resize', setWidthDebounced);
    }
  }, [canvasRef, nextParticleInstance]);

  return (
    <div className="particles">
      <img
        className="particles-image"
        ref={imageRef}
        style={{ visibility: 'hidden' }}
      />
      <canvas ref={canvasRef} />
    </div>
  );
};

export default memo(Particles);
