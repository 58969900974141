import React from 'react';

const RombIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={20}
    viewBox="0 0 23 20"
    fill="none"
  >
    <path
      d="M11.4993 1.6001L1.29932 10.0001L11.4993 18.4001L21.6993 10.0001L11.4993 1.6001Z"
      stroke="#010812"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RombIcon;
