import '../styles/button.scss';

import { Button as AntdButton } from 'antd';
import { ButtonProps as AntdButtonProps } from 'antd/lib/button';
import { navigate } from 'gatsby';
import React, { memo } from 'react';

import { useButtonData } from '../data/hooks';
import { useLinkResolver } from '../hooks/use-link-resolver';
import { omit } from '../utils';
import ArrowRightIcon from './icons/arrow-right';
import { Box } from './layout';

export interface ButtonProps {
  id: string;
  defaultProps?: AntdButtonProps;
}

const Button: React.FC<ButtonProps> = ({
  id,
  defaultProps = { type: 'primary' },
}) => {
  const { data } = useButtonData(id);
  const resolveLink = useLinkResolver();

  const hrefProperty = data.url ? { href: data.url } : {};
  const onClickProperty = data.link
    ? { onClick: () => navigate(resolveLink(data.link)) }
    : {};

  const buttonProps = {
    ...onClickProperty,
    ...hrefProperty,
    ...defaultProps,
    ...omit(data, ['label', 'link', 'url']),
  };

  return (
    <>
      <AntdButton
        className="enhanced-button"
        {...buttonProps}
        aria-label={data.label}
      >
        {buttonProps.type === 'dashed' ? (
          <Box align="middle">
            <span style={{ marginRight: '0.5rem' }}>{data.label}</span>
            <ArrowRightIcon />
          </Box>
        ) : (
          data.label
        )}
      </AntdButton>
    </>
  );
};

export default memo(Button);
