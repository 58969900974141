import { Button, Input } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import {
  LoadCanvasTemplateNoReload,
  loadCaptchaEnginge,
} from 'react-simple-captcha';

import { usePrevious } from '../../hooks';
import { isTaskResolved } from '../../utils';
import { Box } from '../layout';

interface CaptchaInputProps {
  onChange: (captchaValue: string) => void;
  loading: boolean;
}

const DEFAULT_CAPTCHA_SIZE = 6;

const CaptchaInput: React.FC<CaptchaInputProps> = ({ onChange, loading }) => {
  const [inputValue, setInputValue] = useState<string>();
  const previousLoading = usePrevious(loading);

  useEffect(() => {
    loadCaptchaEnginge(DEFAULT_CAPTCHA_SIZE);
  }, []);

  useEffect(() => {
    if (isTaskResolved({ loading }, { loading: previousLoading })) {
      loadCaptchaEnginge(DEFAULT_CAPTCHA_SIZE);
      setInputValue('');
    }
  }, [loading]);

  return (
    <Box justify="space-between" align="middle">
      <Box>
        <LoadCanvasTemplateNoReload />
        <Button
          type="text"
          onClick={() => loadCaptchaEnginge(DEFAULT_CAPTCHA_SIZE)}
        >
          Neue Capcha
        </Button>
      </Box>
      <Input
        maxLength={DEFAULT_CAPTCHA_SIZE}
        size="small"
        style={{ width: '9.125rem', fontSize: '0.875rem' }}
        placeholder="Captcha eingeben"
        onChange={event => {
          setInputValue(event.target.value);
          onChange(event.target.value);
        }}
        value={inputValue}
      />
    </Box>
  );
};

export default memo(CaptchaInput);
