import { Slider, Typography } from 'antd';
import React, { memo, useState } from 'react';

import { useConfigData } from '../../data';
import { computeCurrency } from '../../utils';
import { Box } from '../layout';

interface SliderInputProps {
  value?: any;
  onChange?: (value: string) => void;
  disabled?: boolean;
  unit?: string;
}

const SLIDER_DEFAULT_MIN = 0;
const SLIDER_DEFAULT_MAX = 100;
const SLIDER_DEFAULT_STEP = 1;
const SLIDER_MULTIPLIER = 50000;

const makeDefaultSliderValue = currency => [
  computeCurrency(SLIDER_DEFAULT_MIN * SLIDER_MULTIPLIER, currency),
  computeCurrency(SLIDER_DEFAULT_MAX * SLIDER_MULTIPLIER, currency),
];

const SliderInput: React.FC<SliderInputProps> = ({ onChange, disabled }) => {
  const { data: configData } = useConfigData().fallback;

  const [sliderToolbarValue, setSliderToolbarValue] = useState<string[]>(
    makeDefaultSliderValue(configData.currency),
  );

  return (
    <>
      <Slider
        disabled={disabled}
        onChange={([budgetMin, budgetMax]: any) => {
          const sliderValue = [
            `${computeCurrency(
              budgetMin * SLIDER_MULTIPLIER,
              configData.currency,
            )}`,
            `${computeCurrency(
              budgetMax * SLIDER_MULTIPLIER,
              configData.currency,
            )}${budgetMax === 100 ? ' +' : ''}`,
          ];

          setSliderToolbarValue(sliderValue);

          onChange(sliderValue.join(' - '));
        }}
        range
        tooltipVisible={false}
        defaultValue={[SLIDER_DEFAULT_MIN, SLIDER_DEFAULT_MAX]}
        step={SLIDER_DEFAULT_STEP}
      />
      <Box align="middle" justify="space-between">
        <Typography.Paragraph>{sliderToolbarValue[0]}</Typography.Paragraph>
        <Typography.Paragraph>{sliderToolbarValue[1]}</Typography.Paragraph>
      </Box>
    </>
  );
};

export default memo(SliderInput);
