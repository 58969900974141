import '../../../styles/map.scss';

import { MapboxOptions } from 'mapbox-gl';
import React, { memo, useEffect, useRef, useState } from 'react';

import { mapboxgl } from '../../../fragments';
import BuildingsLayer from './buildings-layer';
import CircleLayer from './circle-layer';
import { MAP_STYLE_URL } from './config';
import MapControls from './map-controls';
import MarkerLayer from './marker-layer';
import { Coordinates } from './types';

interface MapProps {
  coordinates: Coordinates;
  showMarker?: boolean;
  showCircle?: boolean;
  mapInstanceOptions?: Partial<MapboxOptions>;
}

const DEFAULT_MAP_INSTANCE_OPTIONS: Partial<MapboxOptions> = {
  zoom: 12,
};

const Map: React.FC<MapProps> = ({
  coordinates,
  showMarker = false,
  showCircle = false,
  mapInstanceOptions = {},
}) => {
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<mapboxgl.Map>();

  useEffect(() => {
    if (mapContainerRef.current) {
      const map = new mapboxgl.Map({
        center: coordinates,
        container: mapContainerRef.current,
        style: MAP_STYLE_URL,
        ...DEFAULT_MAP_INSTANCE_OPTIONS,
        ...mapInstanceOptions,
      });

      map.on('load', () => {
        setMap(map);
      });
    }
  }, []);

  return (
    <div style={{ position: 'relative', height: '37.5rem', width: '100%' }}>
      <div className="map" ref={mapContainerRef}></div>

      <BuildingsLayer map={map} />

      {showCircle && <CircleLayer map={map} coordinates={coordinates} />}

      {showMarker && <MarkerLayer map={map} coordinates={coordinates} />}

      <MapControls map={map} />
    </div>
  );
};

export default memo(Map);
