import { Form as AntdForm, Input, InputNumber } from 'antd';
import React, { memo } from 'react';

import { FormFieldData, FormFieldType, useFormFieldData } from '../data';
import PhoneInput from './common/phone-input';
import SliderInput from './common/slider-input';
import TilesInput from './common/tiles-input';

interface FormFieldProps {
  id: string;
}

const makeFormFieldInput = (field: FormFieldData['data']) => {
  const inputProps = {
    placeholder: field.label,
  };
  switch (field.type) {
    case FormFieldType.Text:
      return <Input type="text" {...inputProps} />;
    case FormFieldType.Email:
      return <Input type="email" {...inputProps} />;
    case FormFieldType.Textarea:
      return <Input.TextArea rows={4} {...inputProps} />;
    case FormFieldType.Number:
      return <InputNumber {...inputProps} />;
    case FormFieldType.PhoneNumber:
      return <PhoneInput />;
    case FormFieldType.Slider:
      return <SliderInput />;
    case FormFieldType.Checkbox:
    case FormFieldType.Radio:
      return <TilesInput type={field.type} options={field.options} />;
  }
};

const FormField: React.FC<FormFieldProps> = ({ id }) => {
  const { data } = useFormFieldData(id);

  return (
    <AntdForm.Item
      name={data.name}
      help={data.helpMessage}
      rules={[
        {
          message: data.validationMessage,
          required: Boolean(data.required),
        },
      ]}
    >
      {makeFormFieldInput(data)}
    </AntdForm.Item>
  );
};

export default memo(FormField);
