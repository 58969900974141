import '../styles/real-estate-item.scss';

import { Button, Typography } from 'antd';
import { navigate } from 'gatsby';
import React, { memo } from 'react';

import Image from '../components/image';
import { Box } from '../components/layout';
import {
  RealEstateDetailsPartial,
  RealEstateState,
  useConfigData,
  usePageData,
  useRealEstateTypeData,
} from '../data';
import {
  classNameMap,
  computeCurrency,
  resolveRealEstateDetailsPagePath,
} from '../utils';
import MapMarker from './common/map-marker';
import ArrowRightIcon from './icons/arrow-right';
import CurrencyIcon from './icons/currency';
import RombIcon from './icons/romb';

export const RealEstateItemMainParameters: React.FC<{
  item: RealEstateDetailsPartial;
  bordered?: boolean;
  showRealEstateType?: boolean;
}> = ({ item, bordered = false, showRealEstateType = false }) => {
  const { data: configData } = useConfigData().fallback;
  const { data: realEstateTypeData } = useRealEstateTypeData(item.type.id);

  const parametersComponents = [
    <>
      <RombIcon />
      <span>
        ca.{` `}
        {item.size}
        {` `}m<sup>2</sup>
      </span>
    </>,
    <>
      {showRealEstateType && (
        <>
          <Image id={realEstateTypeData.icon.id} />
          <span>{realEstateTypeData.name}</span>
        </>
      )}
    </>,
    <>
      <MapMarker />
      <span>{item.formattedLocation}</span>
    </>,
    <>
      <CurrencyIcon />
      <span>
        {item.price
          ? computeCurrency(item.price, configData.currency)
          : configData.translations?.priceRequest}
      </span>
    </>,
  ];

  return (
    <Box className="real-estate-item-main-parameters">
      {parametersComponents.map((component, index) => (
        <Box
          key={index}
          className={classNameMap({
            'real-estate-item-main-parameters__item': true,
            'real-estate-item-main-parameters__item-bordered': bordered,
          })}
        >
          {component}
        </Box>
      ))}
    </Box>
  );
};

const RealEstateItem: React.FC<{
  item: RealEstateDetailsPartial;
  fullWidth?: boolean;
}> = ({ item, fullWidth = false }) => {
  const pagesData = usePageData().list;

  const isRealEstateNotActive = [
    RealEstateState.Sold,
    RealEstateState.Reserved,
  ].includes(item.state);

  return (
    <Box className="real-estate-item" justify="center" direction="column">
      <Box
        className={classNameMap({
          'real-estate-item__box': true,
          'real-estate-item__box--full-width': fullWidth,
        })}
      >
        <Box
          className={classNameMap({
            'real-estate-item__image-box': true,
            'real-estate-item__image-box--layer': isRealEstateNotActive,
          })}
        >
          <Image id={item.image.id} />

          {isRealEstateNotActive && (
            <Box
              justify="center"
              align="middle"
              className="real-estate-item__image-box--layer-text"
            >
              <Typography.Paragraph>{item.state}</Typography.Paragraph>
            </Box>
          )}
        </Box>

        <Box className="real-estate-item__content">
          <Typography.Title level={4}>{item.title}</Typography.Title>

          <RealEstateItemMainParameters item={item} showRealEstateType />

          {isRealEstateNotActive === false && (
            <Box justify="end">
              <Button
                className="enhanced-button"
                type="dashed"
                onClick={() =>
                  navigate(resolveRealEstateDetailsPagePath(pagesData, item))
                }
                aria-label="Mehr"
              >
                <Box align="middle">
                  <span style={{ marginRight: '0.5rem' }}>Mehr</span>
                  <ArrowRightIcon />
                </Box>
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default memo(RealEstateItem);
