import '../styles/cta-section.scss';

import React, { memo } from 'react';

import { ctaSectionParticleBase64 } from '../config';
import { useCtaSectionData } from '../data';
import Button from './button';
import SectionHeading from './common/section-heading';
import { Box, Section } from './layout';
import Particles from './particles';

interface CtaSectionProps {
  id: string;
}

const CtaSection: React.FC<CtaSectionProps> = ({ id }) => {
  const { data } = useCtaSectionData(id);

  return (
    <Section className="cta-section">
      <Particles imageSrc={ctaSectionParticleBase64} height={400} />

      <Box
        justify="center"
        align="middle"
        direction="column"
        className="cta-section__box"
      >
        <SectionHeading title={data.title} align="center" hideLine />
        <Button id={data.button.id} defaultProps={{ type: 'default' }} />
      </Box>
    </Section>
  );
};

export default memo(CtaSection);
