import '../styles/form.scss';

import {
  Button,
  Checkbox,
  Form as AntdForm,
  notification,
  Typography,
} from 'antd';
import { Link } from 'gatsby';
import React, { memo, useState } from 'react';
import { validateCaptcha } from 'react-simple-captcha';

import { StaticPageId, useConfigData, useFormData } from '../data';
import { win } from '../fragments';
import { useLinkResolver } from '../hooks';
import { post } from '../rest';
import CaptchaInput from './common/captcha-input';
import FormField from './form-field';
import { Box } from './layout';

interface FormProps {
  id: string;
}

const Form: React.FC<FormProps> = ({ id }) => {
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [captchaValue, setCaptchaValue] = useState<string>();
  const linkResolver = useLinkResolver();

  const { data: configData } = useConfigData().fallback;
  const {
    data: { fields, fieldsGroups },
  } = useFormData(id);

  const [form] = AntdForm.useForm();

  const handleFormSubmit = async fieldsValues => {
    if (validateCaptcha(captchaValue) === false) {
      notification.error({
        message: configData.translations?.captchaErrorMessage,
      });

      return;
    }

    await form?.validateFields();

    try {
      setFormLoading(true);
      await post(
        configData.formActionUrl,
        { ...fieldsValues, location: win?.location.href } ?? {},
      );
      setFormLoading(false);
      form.resetFields();

      notification.success({
        message: configData.translations?.formSuccessMessage ?? 'Success',
      });
    } catch {
      setFormLoading(false);
      notification.error({
        message: configData.translations?.formErrorMessage ?? 'Error',
      });
    }
  };

  const renderFields = items =>
    items.map((field, index) => <FormField id={field.id} key={index} />);

  return (
    <>
      <AntdForm
        form={form}
        onFinish={handleFormSubmit}
        layout="vertical"
        scrollToFirstError
      >
        {fields && renderFields(fields)}

        {fieldsGroups &&
          fieldsGroups?.map((group, index) => (
            <Box key={index} className="form-fields-group">
              <Typography.Paragraph strong>
                {index + 1}.{` `}
                {group.title}
              </Typography.Paragraph>
              {renderFields(group.fields)}
            </Box>
          ))}

        <AntdForm.Item
          name="Ich stimme der Verarbeitung meiner persönlichen Daten zu."
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: 'Datenschutzerklärung bitte akzeptieren',
            },
          ]}
        >
          <Checkbox>
            <Typography.Paragraph style={{ margin: 0 }}>
              Ich stimme der Verarbeitung meiner persönlichen Daten zu.{' '}
            </Typography.Paragraph>
            <Link
              to={linkResolver(StaticPageId.PrivacyPolicy)}
              style={{ textDecoration: 'underline' }}
            >
              Datenschutz-Bestimmungen
            </Link>
          </Checkbox>
        </AntdForm.Item>

        <AntdForm.Item>
          <Box justify="space-between">
            <CaptchaInput
              onChange={captchaValue => setCaptchaValue(captchaValue)}
              loading={formLoading}
            />
            <Button
              htmlType="submit"
              type="primary"
              className="enhanced-button submit-button"
              loading={formLoading}
              disabled={formLoading}
              aria-label="Absenden"
            >
              Absenden
            </Button>
          </Box>
        </AntdForm.Item>
      </AntdForm>
    </>
  );
};

export default memo(Form);
