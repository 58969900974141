import '../../styles/tiles-input.scss';

import { Typography } from 'antd';
import React, { memo, useState } from 'react';

import { FormFieldOption, FormFieldType } from '../../data';
import { classNameMap } from '../../utils/tsx';
import Image from '../image';
import { Box } from '../layout';

interface TilesInputProps {
  value?: string[];
  onChange?: (value: string[]) => void;
  disabled?: boolean;
  type?: FormFieldType;
  options?: FormFieldOption[];
}

const TilesInput: React.FC<TilesInputProps> = ({
  value,
  onChange,
  disabled,
  type,
  options,
}) => {
  const [tilesInputValue, setTilesInputValue] = useState<string[]>(value ?? []);

  const makeUpdate = option => {
    if (type === 'checkbox') {
      return [...(tilesInputValue ?? []), option.value];
    }

    if (type === 'radio') {
      return option.value;
    }
  };

  return (
    <Box className="tiles-input">
      {options.map((option, index) => (
        <button
          type="button"
          disabled={disabled}
          className={classNameMap({
            'tiles-input-option': true,
            'tiles-input-option__selected': tilesInputValue.includes(
              option.value,
            ),
          })}
          key={index}
          onClick={() => {
            const update = tilesInputValue.includes(option.value)
              ? tilesInputValue.filter(value => value !== option.value)
              : makeUpdate(option);

            onChange(update);
            setTilesInputValue(update);
          }}
          aria-label={option.label}
        >
          {option.icon && <Image id={option.icon.id} />}
          <Typography.Paragraph className="label">
            {option.label}
          </Typography.Paragraph>
        </button>
      ))}
    </Box>
  );
};

export default memo(TilesInput);
