import '../styles/carousel.scss';

import { Button, Carousel as AntdCarousel } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import React, { memo, useRef, useState } from 'react';

import { CAROUSEL_AUTOPLAY_TIMEOUT } from '../config';
import { classNameMap } from '../utils';
import ArrowSimpleLeftIcon from './icons/arrow-simple-left';
import { Box, LayoutBreakpoint, useBreakpoint } from './layout';

export interface CarouselProps {
  slides: { component: React.ReactNode }[];
  hideControls?: boolean;
}

const Carousel: React.FC<CarouselProps> = ({
  slides,
  hideControls = false,
}) => {
  const [selectedSlide, setSelectedSlide] = useState<number>(0);
  const isMd = useBreakpoint(LayoutBreakpoint.MD);
  const carouselRef = useRef<CarouselRef>();

  const shouldDisplayControls =
    isMd && hideControls === false && slides.length > 1;

  return (
    <>
      <Box className="carousel-container">
        {shouldDisplayControls && (
          <Box className="carousel-button-box carousel-button-box__left">
            <Button
              type="text"
              onClick={() => carouselRef?.current?.prev()}
              aria-label="previous"
            >
              <ArrowSimpleLeftIcon />
            </Button>
          </Box>
        )}

        <Box
          justify={{ md: 'space-between' }}
          align={{ md: 'middle' }}
          className="carousel-container-content-box"
        >
          <AntdCarousel
            autoplay
            autoplaySpeed={CAROUSEL_AUTOPLAY_TIMEOUT}
            dots={false}
            ref={carouselRef}
            afterChange={currentSlide => setSelectedSlide(currentSlide)}
          >
            {slides.map((slide, index) => (
              <div key={index}>
                <div className="carousel-container-content">
                  {slide.component}
                </div>
              </div>
            ))}
          </AntdCarousel>
        </Box>

        {shouldDisplayControls && (
          <Box className="carousel-button-box carousel-button-box__right">
            <Button
              type="text"
              onClick={() => carouselRef?.current?.next()}
              aria-label="next"
            >
              <ArrowSimpleLeftIcon />
            </Button>
          </Box>
        )}
      </Box>

      <Box justify="center" align="middle">
        {slides.map((_, index) => (
          <button
            key={index}
            className={classNameMap({
              'carousel-controls-button': true,
              'carousel-controls-button--active': index === selectedSlide,
            })}
            onClick={() => carouselRef?.current?.goTo(index)}
            aria-label={`controls ${index}`}
          />
        ))}
      </Box>
    </>
  );
};

export default memo(Carousel);
