import React, { memo } from 'react';

const MapMarker: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M20.999 10C20.999 17 11.999 23 11.999 23C11.999 23 2.99902 17 2.99902 10C2.99902 7.61305 3.94723 5.32387 5.63506 3.63604C7.32289 1.94821 9.61208 1 11.999 1C14.386 1 16.6752 1.94821 18.363 3.63604C20.0508 5.32387 20.999 7.61305 20.999 10Z"
      stroke="#010812"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.999 13C13.6559 13 14.999 11.6569 14.999 10C14.999 8.34315 13.6559 7 11.999 7C10.3422 7 8.99902 8.34315 8.99902 10C8.99902 11.6569 10.3422 13 11.999 13Z"
      stroke="#010812"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default memo(MapMarker);
