import { Typography } from 'antd';
import React, { useEffect, useRef } from 'react';

import { useConfigData } from '../../../data';
import { mapboxgl } from '../../../fragments';
import { Coordinates } from './types';

export interface BuildingsLayerProps {
  map?: mapboxgl.Map;
  coordinates: Coordinates;
}
const MarkerLayer: React.FC<BuildingsLayerProps> = ({ map, coordinates }) => {
  const { data: configData } = useConfigData().fallback;
  const popupElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!map) return;

    const popup = new mapboxgl.Popup({ offset: 25 }).setDOMContent(
      popupElementRef.current,
    );

    new mapboxgl.Marker({
      color: configData.theme.primaryColor,
    })
      .setLngLat(coordinates)
      .setPopup(popup)
      .addTo(map);
  }, [map]);

  return (
    <>
      {map && (
        <div ref={popupElementRef}>
          <Typography.Paragraph style={{ color: '#000' }} strong>
            Klosterbuschweg 14, 13591 Berlin, Germany
          </Typography.Paragraph>
          <Typography.Link
            href="https://www.google.com/maps/dir//Klosterbuschweg+14,+13591+Berlin,+Germany/@52.5386434,13.0873683,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47a8f884d2ccdeeb:0x1edfa7c24556b5b!2m2!1d13.1574075!2d52.5386641?hl=en-GB"
            target="_blank"
            aria-label="directions"
            rel="noreferrer"
            style={{ color: '#4264fb' }}
          >
            Eine Wegbeschreibung zu diesem Standort finden Sie auf Google Maps.
          </Typography.Link>
        </div>
      )}
    </>
  );
};

export default MarkerLayer;
