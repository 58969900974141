import React, { useEffect } from 'react';

import { BUILDINGS_LAYER_ID } from './config';

export interface BuildingsLayerProps {
  map?: mapboxgl.Map;
}

const BuildingsLayer: React.FC<BuildingsLayerProps> = ({ map }) => {
  useEffect(() => {
    if (!map) return;
    map.addLayer({
      id: BUILDINGS_LAYER_ID,
      source: 'composite',
      'source-layer': 'building',
      filter: ['==', 'extrude', 'true'],
      type: 'fill-extrusion',
      minzoom: 15,
      paint: {
        'fill-extrusion-color': '#69c0ff',
        'fill-extrusion-height': [
          'interpolate',
          ['linear'],
          ['zoom'],
          15,
          0,
          15.05,
          ['get', 'height'],
        ],
        'fill-extrusion-base': [
          'interpolate',
          ['linear'],
          ['zoom'],
          15,
          0,
          15.05,
          ['get', 'min_height'],
        ],
        'fill-extrusion-opacity': 0.6,
      },
    });
  }, [map]);

  return <></>;
};

export default BuildingsLayer;
