import React, { useEffect } from 'react';

import { useConfigData } from '../../../data';
import { LayoutBreakpoint, useBreakpoint } from '../../layout';
import { CIRCLE_LAYER_ID } from './config';
import { Coordinates } from './types';

export interface CircleLayerProps {
  map: mapboxgl.Map;
  coordinates: Coordinates;
}

const FEATURE_SOURCE_ID = 'circle-markers';

const CircleLayer: React.FC<CircleLayerProps> = ({ map, coordinates }) => {
  const { data: configData } = useConfigData().fallback;
  const isMd = useBreakpoint(LayoutBreakpoint.MD);

  useEffect(() => {
    if (!map) return;

    map.addSource(FEATURE_SOURCE_ID, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [coordinates.lng, coordinates.lat],
            },
            properties: {
              modelId: FEATURE_SOURCE_ID,
            },
          },
        ],
      },
    });

    map.addLayer({
      id: CIRCLE_LAYER_ID,
      source: FEATURE_SOURCE_ID,
      type: 'circle',
      maxzoom: 16,
      paint: {
        'circle-radius': 0,
        'circle-stroke-width': isMd ? 250 : 160,
        'circle-stroke-color': configData.theme.primaryColor,
        'circle-stroke-opacity': 0.7,
      },
      filter: ['==', 'modelId', FEATURE_SOURCE_ID],
    });
  }, [map]);

  return <></>;
};

export default CircleLayer;
