import React from 'react';

const CurrencyIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={20}
    viewBox="0 0 19 20"
    fill="none"
  >
    <path
      d="M16.6993 15.41C16.1667 16.1822 15.4604 16.8188 14.6372 17.2686C13.814 17.7184 12.8968 17.9689 11.9593 18C9.27927 18 6.95927 16 5.95927 13H11.4993C11.7645 13 12.0188 12.8946 12.2064 12.7071C12.3939 12.5196 12.4993 12.2652 12.4993 12C12.4993 11.7348 12.3939 11.4804 12.2064 11.2929C12.0188 11.1054 11.7645 11 11.4993 11H5.54927C5.54927 10.67 5.49927 10.33 5.49927 10C5.49927 9.67 5.49927 9.33 5.54927 9H11.4993C11.7645 9 12.0188 8.89464 12.2064 8.70711C12.3939 8.51957 12.4993 8.26522 12.4993 8C12.4993 7.73478 12.3939 7.48043 12.2064 7.29289C12.0188 7.10536 11.7645 7 11.4993 7H5.96927C6.96927 4 9.27927 2 11.9693 2C12.9051 2.03267 13.8203 2.28392 14.6416 2.73366C15.463 3.1834 16.1676 3.81913 16.6993 4.59C16.7698 4.71152 16.8652 4.8168 16.9792 4.89898C17.0932 4.98116 17.2232 5.03839 17.3608 5.06694C17.4984 5.09549 17.6405 5.09472 17.7778 5.06468C17.9151 5.03464 18.0445 4.976 18.1576 4.8926C18.2707 4.80919 18.3649 4.70288 18.4342 4.58061C18.5034 4.45833 18.5461 4.32283 18.5595 4.18294C18.5728 4.04305 18.5566 3.90192 18.5118 3.76874C18.4669 3.63556 18.3945 3.51332 18.2993 3.41C17.587 2.38118 16.6411 1.53577 15.5391 0.943041C14.4371 0.350313 13.2103 0.027138 11.9593 0C8.19927 0 4.95927 2.84 3.88927 7H1.49927C1.23405 7 0.979697 7.10536 0.792161 7.29289C0.604624 7.48043 0.499268 7.73478 0.499268 8C0.499268 8.26522 0.604624 8.51957 0.792161 8.70711C0.979697 8.89464 1.23405 9 1.49927 9H3.54927C3.54927 9.33 3.54927 9.67 3.54927 10C3.54927 10.33 3.54927 10.67 3.54927 11H1.49927C1.23405 11 0.979697 11.1054 0.792161 11.2929C0.604624 11.4804 0.499268 11.7348 0.499268 12C0.499268 12.2652 0.604624 12.5196 0.792161 12.7071C0.979697 12.8946 1.23405 13 1.49927 13H3.88927C4.97927 17.16 8.19927 20 11.9593 20C13.2103 19.9729 14.4371 19.6497 15.5391 19.057C16.6411 18.4642 17.587 17.6188 18.2993 16.59C18.423 16.377 18.4637 16.1258 18.4137 15.8846C18.3636 15.6434 18.2263 15.4292 18.0281 15.283C17.8298 15.1368 17.5846 15.0689 17.3394 15.0923C17.0942 15.1158 16.8662 15.2289 16.6993 15.41Z"
      fill="#010812"
    />
  </svg>
);

export default CurrencyIcon;
